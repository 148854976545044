import dailylife from "../assets/images/dailylife.jpg";
import housing from "../assets/images/housing.jpg";
import school from "../assets/images/school.jpg";
import immigration from "../assets/images/immigration.jpg";
import career from "../assets/images/career.jpg";
import meetup from "../assets/images/meetup.jpg";
import accommodation from "../assets/images/accommodation.jpg"



const Selection = [
    {
        id:1,
        image: dailylife,
        header: "Daily Life",
        body: "Unlock the secrets to a smooth transition! Get local insights, news, and tips to make your new house feel like home."
    },
    {
        id:2,
        image: housing,
        header: "Housing",
        body: "Find your dream home with our reliable resources! From apartments to homestays, we've got you covered."
    },
    {
        id:3,
        image: accommodation,
        header: "Accommodation",
        body: "Explore short-term and long-term stays with our trusted partners! Get settled in no time."
    },
    {
        id:4,
        image: school,
        header: "School",
        body: "Discover the right school for you! Browse our directory of schools, universities, and language courses."
    },
    {
        id:5,
        image: immigration,
        header: "Immigration",
        body: "Stay ahead of the game with our immigration resources! Get updates, ask questions, and connect with experts."
    },
    {
        id:6,
        image: meetup,
        header: "Meet Up",
        body: "Join our community events and connect with like-minded individuals! Make friends, learn, and grow together"
    },
    {
        id:7,
        image: career,
        header: "Career",
        body: "Boost your career with our job search and development resources! Connect with professionals and attend networking events."
    },

    
]

export default Selection;

