import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import Logo from "../assets/images/homelogo.svg";
import { Link } from "react-router-dom";
import { useScroll } from "./Scrollcontext";

const Navbar = () => {
  const { scrollToBottom } = useScroll();

  const [Nav, setNav] = useState(false);
  const handleClick = () => setNav(!Nav);
  return (
    <div className="fixed w-full h-[80px] flex justify-between items-center px-5 sm:px-20 md:px-10 lg:px-32 py-4 bg-[#F5F7FA] text-navText z-50">
      <div>
        <Link to="home">
          <img
            src={Logo}
            alt="Logoimage"
            style={{ width: "50px" }}
            className="cursor-pointer"
          />
        </Link>
      </div>

      {/* menu*/}
      <ul className="hidden md:flex">
        <li>
          <Link
            to="home"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white  active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            to="about"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white  active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            About
          </Link>{" "}
        </li>
        <li>
          <Link
            to="feature"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Features
          </Link>{" "}
        </li>
        <li>
          <Link
            to="resources"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Resources
          </Link>{" "}
        </li>
        <li>
          <Link
            to="contact"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Contact
          </Link>{" "}
        </li>
      </ul>

      <div>
        <button
          className="bg-black font-normal text-sm text-white px-3 py-1 md:px-6 md:py-3 lg:px-6 lg:py-3 my-2 rounded-md"
          onClick={scrollToBottom}
        >
          Download
        </button>
      </div>

      {/* Hamburger */}
      <div onClick={handleClick} className="md:hidden z-10">
        {!Nav ? <FaBars className="bg-[#e3e5e9] font-" /> : <FaTimes />}
      </div>
      {/* Mobile Menu */}
      <ul
        className={
          !Nav
            ? "hidden"
            : "absolute top-0 left-0 w-full h-screen bg-[#F5F7FA] flex flex-col justify-center items-center"
        }
      >
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="home"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white  active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Home
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="about"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white  active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            About
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="feature"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white  active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Features
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="resources"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white  active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Resources
          </Link>
        </li>
        <li className="py-6 text-4xl">
          <Link
            onClick={handleClick}
            to="contact"
            className="hover:ring-2 hover:bg-blue-500 hover:text-white  active:bg-blue-700 focus:outline-none focus:ring-2 focus:bg-[#0051F9] p-2 rounded-md "
          >
            Contact
          </Link>
        </li>
      </ul>
      {/*Social Menu */}
    </div>
  );
};

export default Navbar;
