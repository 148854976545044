import React from "react";
import { Link } from "react-router-dom";

const Legal = () => {
  return (
    <div className=" w-full  bg-[#F5F7FA] pt-10 sm:pt-10 md:pt-28 lg:pt-40  md:px-10 lg:px-32 md:h-auto">
      <h1 className="text-5xl font-bold pb-8">Home Legal</h1>
      
      <div className=" pb-[25rem]">
        <ul className="list-disc list-inside">
          <Link to="end-user-license-agreement">
            <li className="text-sky-600">
              <span>End User License Agreement</span>
            </li>
          </Link>
          <Link to="privacy-policy">
            <li className="text-sky-600">Privacy Policy</li>
          </Link>
        </ul>
      </div>

      {/* <div>
        <p><img src=""/><span className="text-sky-600">End User License Agreement</span></p>
      </div> */}
      {/* <ul className="list-disc ">
        <li className="relative text-sky-600 before:absolute before:inset-0 before:content-[''] before:w-2 before:h-2 before:bg-transparent before:left-[-1.5rem] before:top-1/2 before:-translate-y-1/2">
          <span className="pl-4">End User License Agreement</span>
        </li>
        <li className="relative text-sky-600 before:absolute before:inset-0 before:content-[''] before:w-2 before:h-2 before:bg-transparent before:left-[-1.5rem] before:top-1/2 before:-translate-y-1/2">
          <span className="pl-4">Privacy Policy</span>
        </li>
      </ul> */}
    </div>
  );
};

export default Legal;
