import React from "react";
import PhoneDial from "../assets/images/phone.svg";
import Mail from "../assets/images/mail.svg";
import Location from "../assets/images/location.svg";

const Contact = () => {
  return (
    <div className="bg-[#F5F7FA] px-4 sm:px-4 md:px-10 lg:px-[10rem] xl:px-[10rem] pt-32 pb-[21rem]">
      <div className=" bg-white rounded-tr-3xl rounded-tl-3xl rounded-br-3xl rounded-bl p-8 sm:p-8 md:p-6 lg:p-3  shadow-lg text-center  ">
        <div className=" bg-black text-white text-center rounded-tr-3xl rounded-tl-3xl rounded-br-3xl rounded-bl h-full  pt-8 pb-44 pl-8 lg:pr-32 md:p-6 lg:p-12 ">
          <div>
            <h1 className="text-4xl sm:text-5xl md:text-4xl lg:text-4xl font-semibold">
              {" "}
              Contact Us
            </h1>
            <p className="py-4 max-w-auto">
              Any question or remarks? Just write us a message!
            </p>
          </div>
          <div className="text-white pt-12 text-center">
            <div className="flex justify-center text-center">
              <img src={PhoneDial} alt="Phonedial" className="pr-4" />
              <p className="w-auto">+343 5989723  </p>
            </div>
            <div className="flex py-6 justify-center">
              <img src={Mail} alt="Phonedial" className="pr-4 " />
              <p>admin@home4im.com </p>
            </div>
            <div className="flex justify-center">
              <img
                src={Location}
                alt="Phonedial"
                className="pr-4 "
              />
              <p className="pt-2 ">
              7 Bayview Station Rd, Ottawa, ON K1Y 2C5{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
