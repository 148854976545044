import React from 'react'
import Featurecard from './Featurecard'
import Card from './Card'

const Feature = () => {
  return (
    <div className="bg-[#F5F7FA] px-4 md:px-10 lg:px-32 py-32">
     <div className=" grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-8 sm:gap-12 lg:gap-14 ">
        {Featurecard.map((selectionItem) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <Card
              image={selectionItem.image}
              header={selectionItem.header}
              body={selectionItem.body}
            />
          );
        })}
      </div>
    </div>
  )
}

export default Feature