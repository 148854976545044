import React from "react";
import AboutImage from "../assets/images/Aboutusimage.svg";
import Values from "./OurValues";

const About = () => {
  return (
    <div className="bg-[#F5F7FA] px-4 md:px-10 lg:px-32">
      <div name="home" className=" flex flex-col md:flex-row h-full w-full py-32 ">
        {/*Container*/}
        <div className="h-full my-28 md:text-left md:pr-0 justify-start md:mr-10 lg:mr-52">
          <h1 className="text-4xl sm:text-6xl md:text-4xl lg:text-6xl font-bold text-black sm:max-w-[500px] md:max-w-[700px] lg:max-w-[1000px]">
            About <span className="text-[#0051F9]">Us</span>
          </h1>
          <p className="text-[#717171] font-normal text-base py-4  md:pr-5  md:max-w-[1000px] lg:max-w-[600px]">
            At Home, we understand the challenges and excitement that come with
            starting a new life in a different country. That's why we're
            dedicated to providing newcomers in Canada with the support,
            resources, and connections they need to thrive.
          </p>
        </div>
        <div>
          <img src={AboutImage} alt="homeimage" className="md:w-[80rem] lg:w-[60rem] md:h-auto lg:h-140 md:pt-24 lg:pl-52"/>
        </div>
      </div>
      <div className="flex justify-between mb-4">
        <h1 className="text-lg sm:text-4xl md:text-4xl lg:text-6xl font-medium text-black max-w-[500px] sm:max-w-[500px] md:max-w-[700px] lg:max-w-[1000px] mr-6 md:mt-40 lg:mt-0">
          Our Story
        </h1>
        <div className="text-[#717171] font-light text-sm lg:text-lg lg:font-normal max-w-[350px] md:max-w-[400px] lg:max-w-[900px] ">
          <p className="mb-4 ">
            Home was founded with a mission to empower newcomers and foster a
            sense of belonging in their new communities. We recognize that the
            journey of immigration is filled with unique experiences and
            obstacles, which is why we're committed to creating a platform where
            newcomers can find support, guidance, and friendship.
          </p>
          <p>
            Our vision is to build a vibrant and inclusive community where
            newcomers feel welcomed, supported, and empowered to pursue their
            dreams. We believe in the power of connection and collaboration, and
            we're passionate about creating opportunities for newcomers to
            network, learn, and grow.
          </p>
        </div>
      </div>
      <div className="py-20">
        <div>
          <h4 className="text-lg text-center justify-items-center sm:text-4xl font-medium text-black my-20">
            Our Values
          </h4>
        </div>
        <div>
          <Values />
        </div>
      </div>
    </div>
  );
};

export default About;
