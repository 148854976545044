import React from "react";
import { Link } from "react-router-dom";
import Instagram from "../assets/images/instagram.svg";
import Twitter from "../assets/images/twitter.svg";
// import Arrow from "../assets/images/arrowsearch.svg";
import Linkedin from "../assets/images/linkedin.svg";
import Googleplay from "../assets/images/googleplay.svg";
import Applepay from "../assets/images/applestore.svg";
import { useScroll } from "./Scrollcontext";
import Logo from "../assets/images/homelogo.svg";

const Footer = () => {
  const { bottomRef } = useScroll();

  return (
    // <div ref={bottomRef} className=" bg-black px-4 lg:px-32  ">
    //   <div className=" flex  justify-between items-center text-white md:w-auto lg:w-auto  ">
    //     <div>
    //       <img src={Logo} alt="homelogo" className="w-[2rem] h-[2rem]" />
    //     </div>

    //     <div className="flex items-center">
    //       <p className="text-xs lg:text-base lg:font-semibold ">
    //         2024 ©️ Home4IM. All rights reserved{" "}
    //       </p>
    //       <div className="px-4">|</div>

    //       <div className="flex ">
    //         <a
    //           href="https://www.instagram.com/home4im_?igsh=MTl4MDN5MXRzdXl2dw=="
    //           target="blank"
    //         >
    //           <img src={Instagram} alt="Instagram" className="w-3" />
    //         </a>

    //         <a href="https://www.linkedin.com/company/home4im/" target="blank">
    //           <img src={Linkedin} alt="LinkedIn" className="w-3 h-3 mx-6" />
    //         </a>

    //         <a href="https://x.com/home4_IM" target="blank">
    //           <img src={Twitter} alt="Twitter" className="w-3" />
    //         </a>
    //       </div>
    //       <div className="px-4">|</div>
    //       <div className="text-white">
    //         <Link to="legal">
    //           <p className="text-xs font-extralight lg:text-xs lg:font-semibold ">Terms & Privacy Policy</p>
    //         </Link>
    //       </div>
    //       <div className="px-4 text">|</div>
    //        <div className="flex flex-col md:flex-row lg:flex-row items-center "> 
    //         <div>
    //           <a href="https://t.co/tFBcSChHXn" target="blank">
    //             <img
    //               src={Googleplay}
    //               alt="googleplay"
    //               className=" w-20 h-20 md:w-15 md:h-15 lg:w-20 lg:h-20 sm:w-20"
    //             />
    //           </a>
    //         </div>
    //          <div className="ml-2">
    //           <a
    //             href="https://apps.apple.com/app/home4im/id6471267114"
    //             target="blank"
    //           >
    //             <img
    //             src={Applepay}
    //             alt="googleplay"
    //             className="ml-0 md:ml-4 w-10 h-10 lg:ml-4 sm:w-20 md:w-15 md:h-15 lg:w-20 lg:h-20"
    //           />
    //           </a>
              
    //         </div>
    //       </div> 
    //     </div>
    //   </div>
      
    // </div>
    <div ref={bottomRef} className="bg-black px-4 pt-4 sm:pt-4 md:pt-0 lg:pt-0 lg:px-32">
  <div className="flex flex-col sm:flex-row justify-between items-center text-white md:w-auto lg:w-auto">
    <div>
      <img src={Logo} alt="homelogo" className="w-[2rem] h-[2rem]" />
    </div>

    <div className="flex flex-col sm:flex-row items-center mt-4 sm:mt-0">
      <p className="text-xs font-extralight ">
        2024 ©️ Home4IM. All rights reserved{" "}
      </p>
      <div className="px-4 hidden sm:block">|</div>

      <div className="flex mt-4 sm:mt-0">
        <a
          href="https://www.instagram.com/home4im_?igsh=MTl4MDN5MXRzdXl2dw=="
          target="blank"
        >
          <img src={Instagram} alt="Instagram" className="w-3" />
        </a>

        <a href="https://www.linkedin.com/company/home4im/" target="blank">
          <img src={Linkedin} alt="LinkedIn" className="w-3 h-3 mx-6" />
        </a>

        <a href="https://x.com/home4_IM" target="blank">
          <img src={Twitter} alt="Twitter" className="w-3" />
        </a>
      </div>
      <div className="px-4 hidden sm:block">|</div>
      <div className="text-white mt-4 sm:mt-0">
        <Link to="legal">
          <p className="text-xs font-extralight">
            Terms & Privacy Policy
          </p>
        </Link>
      </div>
      <div className="px-4 hidden sm:block">|</div>
      <div className="flex flex-row sm:flex-row items-center mt-4 sm:mt-0">
        <div>
          <a href="https://t.co/tFBcSChHXn" target="blank">
            <img
              src={Googleplay}
              alt="googleplay"
              className="w-20 h-20 md:w-15 md:h-15 lg:w-20 lg:h-20 sm:w-20"
            />
          </a>
        </div>
        <div className="ml-0 sm:ml-2">
          <a href="https://apps.apple.com/app/home4im/id6471267114" target="blank">
            <img
              src={Applepay}
              alt="googleplay"
              className="ml-0 md:ml-4 w-20 h-20 lg:ml-4 sm:w-20 md:w-15 md:h-15 lg:w-20 lg:h-20"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

  );
};

export default Footer;
