import React from "react";
import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <Link to={props.link}>
      <div className="max-w-sm transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-3xl overflow-hidden shadow-lg">
        <img className="w-full h-[15rem]" src={props.image} alt="service" />
        <div className="px-6 py-4">
          <div className="font-semibold text-lg mb-2 text-[#4D4D4D]">
            {props.header}
          </div>
          <p className="text-[#717171] text-base font-light">{props.body}</p>
        </div>
      </div>
    </Link>
  );
};

export default Card;
