import React from "react";

const Enduser = () => {
  return (
    <div className="p-10 py-[6rem] md:p-8 md:pt-24 lg:px-32 lg:py-24">
      <div>
        <h1 className="font-medium text-3xl px-[0rem] p-2 py-6">
          End User License Agreement (EULA)
        </h1>
        <p>
          This End User License Agreement ("Agreement") is a legal agreement
          between you ("User") and insight-algonquin ("Company") for the use of
          the mobile application "home 4im" ("App"). By downloading, installing,
          or using the App, you agree to be bound by the terms and conditions of
          this Agreement. If you do not agree to the terms and conditions of
          this Agreement, do not download, install, or use the App.
        </p>
      </div>
      <div className="py-4">
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            1. License Grant
          </p>
          <p>
            The Company grants you a revocable, non-exclusive, non-transferable,
            limited license to download, install, and use the App solely for
            your personal, non-commercial purposes strictly in accordance with
            the terms of this Agreement.
          </p>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            2. Restrictions
          </p>
          <p>You agree not to, and you will not permit others to:</p>
          <div>
            <ul className="list-disc pl-8 pt-4">
              <li>
                License, sell, rent, lease, assign, distribute, transmit, host,
                outsource, disclose, or otherwise commercially exploit the App
                or make the App available to any third party.
              </li>
              <li>
                Modify, make derivative works of, disassemble, decrypt, reverse
                compile, or reverse engineer any part of the App.
              </li>
              <li>
                Remove, alter, or obscure any proprietary notice (including any
                notice of copyright or trademark) of the Company or its
                affiliates, partners, suppliers, or the licensors of the App.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            3. Objectionable Content Policy
          </p>
          <p>
            You agree not to use the App to upload, post, transmit, or otherwise
            make available any content that:
          </p>
          <div>
            <ul className="list-disc pl-8 pt-4">
              <li>
                Is unlawful, harmful, threatening, abusive, harassing,
                defamatory, vulgar, obscene, pornographic, libelous, invasive of
                another's privacy, hateful, or racially, ethnically, or
                otherwise objectionable.
              </li>
              <li>Harms minors in any way.</li>
              <li>
                Impersonates any person or entity, including, but not limited
                to, a Company official, forum leader, guide, or host, or falsely
                states or otherwise misrepresents your affiliation with a person
                or entity.
              </li>
              <li>
                Engages in any form of antisocial, disruptive, or destructive
                acts, including "flaming," "spamming," "flooding," "trolling,"
                and "griefing," as those terms are commonly understood and used
                on the internet.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            4. Termination
          </p>
          <p>
            This Agreement is effective until terminated by you or the Company.
            Your rights under this Agreement will terminate automatically
            without notice from the Company if you fail to comply with any
            term(s) of this Agreement. Upon termination of this Agreement, you
            shall cease all use of the App and delete all copies of the App from
            your mobile device or from your desktop.
          </p>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            5. Limitation of Liability
          </p>
          <p>
            To the fullest extent permitted by applicable law, in no event shall
            the Company or its suppliers be liable for any indirect, punitive,
            incidental, special, consequential, or exemplary damages, including
            without limitation damages for loss of profits, goodwill, use, data,
            or other intangible losses, arising out of or relating to the use
            of, or inability to use, this App.
          </p>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            6. Disclaimer of Warranties
          </p>
          <p>
            The App is provided on an "AS IS" and "AS AVAILABLE" basis. The
            Company expressly disclaims all warranties of any kind, whether
            express or implied, including, but not limited to, the implied
            warranties of merchantability, fitness for a particular purpose, and
            non-infringement. The Company makes no warranty that:
          </p>
          <div>
            <ul className="list-disc pl-8 pt-4">
              <li>The App will meet your requirements.</li>
              <li>
                The App will be available on an uninterrupted, timely, secure,
                or error-free basis.
              </li>
              <li>
                The results that may be obtained from the use of the App will be
                accurate or reliable.
              </li>
              <li>
                The quality of any products, services, information, or other
                material purchased or obtained by you through the App will meet
                your expectations.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            7. Governing Law
          </p>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of the united state of America, without regard to its
            conflict of law principles.
          </p>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            8. Changes to this Agreement
          </p>
          <p>
            The Company reserves the right, at its sole discretion, to modify or
            replace this Agreement at any time. If a revision is material, we
            will provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </p>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            9. Contact Information
          </p>
          <p>
            If you have any questions about this Agreement, please contact us
            at:<a href="mailto:admin@home4im.com" className="text-sky-600"> admin@home4im.com</a> 
          </p>
        </div>
      </div>
    </div>
  );
};

export default Enduser;
