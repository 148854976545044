import React from "react";
import House from "../assets/images/house.svg";
import Power from "../assets/images/power.svg";
import People from "../assets/images/people.svg";
import Accessibility from "../assets/images/accessibility.svg";

const Values = () => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
      <div className=" flex flex-col md:flex-row items-center shadow-xl w-full h-auto p-4 md:p-2 transition duration-300 ease-in-out hover:shadow-xl hover:animate-bounce rounded-3xl">
        <div className="flex justify-center items-center rounded-lg md:p-2 mb-4 md:mb-0">
          <img
            className=" bg-[#FFDB82] rounded-xl w-40 h-40 md:w-52 md:h-32 p-2"
            src={House}
            alt="Sunset in the mountains"
          />
        </div>
        <div class="md:ml-4 text-center md:text-left">
          <div class="font-bold text-xl mb-2 text-[#4D4D4D]">Inclusivity </div>
          <p class="text-[#717171] text-base">
            We embrace diversity and strive to create an inclusive environment
            where everyone feels welcomed, respected, and valued.
          </p>
        </div>
      </div>

      <div className=" flex flex-col md:flex-row items-center shadow-xl w-full h-auto p-4 md:p-2 transition duration-300 ease-in-out hover:shadow-xl hover:animate-bounce rounded-3xl">
        <div className="flex justify-center items-center rounded-lg md:p-2 mb-4 md:mb-0">
          <img
            className="bg-[#FFCFCF] rounded-xl w-40 h-40 md:w-52 md:h-32 p-2"
            src={Power}
            alt="Sunset in the mountains"
          />
        </div>

        <div class="md:ml-4 text-center md:text-left">
          <div class="font-bold text-xl mb-2 text-[#4D4D4D]">
            Empowerment{" "}
          </div>
          <p class="text-[#717171] text-base">
          We empower newcomers by providing them with the tools, resources, and connections they need to navigate the challenges of starting a new life in Canada.
          </p>
        </div>
      </div>
      <div className=" flex flex-col md:flex-row items-center shadow-xl w-full h-auto p-4 md:p-2 transition duration-300 ease-in-out hover:shadow-xl hover:animate-bounce rounded-3xl">
        <div className="flex justify-center items-center rounded-lg md:p-2 mb-4 md:mb-0 ">
          <img
            className=" bg-[#82FFCB] rounded-xl w-40 h-20 md:w-40 md:h-32 p-2"
            src={People}
            alt="Sunset in the mountains"
          />
        </div>

        <div class="md:ml-4 text-center md:text-left">
          <div class="font-bold text-xl mb-2 text-[#4D4D4D]">
            Community{" "}
          </div>
          <p class="text-[#717171] text-base">
          We believe in the power of community and the strength that comes from coming together.
          </p>
        </div>
      </div>
      <div className=" flex flex-col md:flex-row items-center shadow-xl w-full h-auto p-4 md:p-2 transition duration-300 ease-in-out hover:shadow-xl hover:animate-bounce rounded-3xl">
        <div className="flex justify-center items-center rounded-lg md:p-2 mb-4 md:mb-0 ">
          <img
            className="bg-[#CADBFF] rounded-xl w-40 h-40 md:w-52 md:h-32 p-2"
            src={Accessibility}
            alt="Sunset in the mountains"
          />
        </div>

        <div class="md:ml-4 text-center md:text-left">
          <div class="font-bold text-xl mb-2 text-[#4D4D4D]">
            Accessibility{" "}
          </div>
          <p class="text-[#717171] text-base">
          We are committed to making our platform accessible to all, ensuring that everyone, regardless of language, location, or ability, can easily access the support and resources they need.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Values;
