import React from "react";
import Community from "../assets/images/community.svg";
import Essential from "../assets/images/essential.svg";
import Engagement from "../assets/images/engagement.svg";

const Explorecard = () => {
  return (
    <div className="flex flex-col sm:flex-row md:flex-row justify-around">
  <div className="text-center flex flex-col justify-center items-center shadow-lg px-4 py-8 my-4 lg:mx-10 lg:w-[24rem] lg:h-[24rem] md:w-[20rem] md:h-[25rem] md:mx-2 transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-3xl">
    <div className="flex justify-center items-center rounded-lg p-6">
      <img
        className="bg-[#FFDB82] rounded-tr-2xl rounded-tl-2xl rounded-br-2xl p-8 w-28 h-20 md:w-14 md:p-2 md:h-14 lg:w-20 lg:h-20"
        src={Community}
        alt="Community Support"
      />
    </div>
    <div className="px-6 py-4">
      <div className="font-bold text-xl mb-2 text-[#4D4D4D]">Community Support</div>
      <p className="text-[#717171] text-base font-light">
        Find your new crew for tips, help, and a place to fit in.
      </p>
    </div>
  </div>
  <div className="text-center flex flex-col justify-center items-center shadow-lg px-4 py-8 my-4 lg:mx-10 lg:w-[24rem] lg:h-[24rem] md:w-[20rem] md:h-[25rem] md:mx-2 transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-3xl">
    <div className="flex justify-center items-center p-6">
      <img
        className="bg-[#FFCFCF] rounded-tr-2xl rounded-tl-2xl rounded-br-2xl p-4 w-20 h-20"
        src={Essential}
        alt="Essential Resources"
      />
    </div>
    <div className="px-6 py-4">
      <div className="font-bold text-xl mb-2 text-[#4D4D4D]">Essential Resources</div>
      <p className="text-[#717171] text-base font-light">
        Ditch the jetlag! Get your Canada life figured out with guides on everything from landing to fitting in.
      </p>
    </div>
  </div>
  <div className="text-center flex flex-col justify-center items-center shadow-lg px-4 py-8 my-4 lg:mx-10 lg:w-[24rem] lg:h-[24rem] md:w-[20rem] md:h-[25rem] md:mx-2 transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-3xl">
    <div className="flex justify-center items-center rounded-lg p-6">
      <img
        className="bg-[#CADBFF] rounded-tr-2xl rounded-tl-2xl rounded-br-2xl p-4 w-20 h-20"
        src={Engagement}
        alt="Engagement"
      />
    </div>
    <div className="px-6 py-4">
      <div className="font-bold text-xl mb-2 text-[#4D4D4D]">Engagement</div>
      <p className="text-[#717171] text-base font-light">
        Find local events to immerse yourself in Canadian culture and build your community.
      </p>
    </div>
  </div>
</div>

//     <div className="flex flex-row md:flex-row lg:flex-row  justify-around">
//   <div className="text-center justify-center items-center shadow-lg px-6.9 py-12 lg:mx-10 lg:w-[24rem] lg:h-[24rem] md:my-10 md:w-[20rem] md:h-[25rem] md:mx-2 transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-3xl">
//     <div className="flex justify-center items-center rounded-lg p-6">
//       <img
//         className="text-center justify-center items-center bg-[#FFDB82] rounded-tr-2xl rounded-tl-2xl rounded-br-2xl p-8 w-28 h-20 md:w-14 md:p-2 md:h-14 lg:w-20 lg:h-20"
//         src={Community}
//         alt="Community Support"
//       />
//     </div>
//     <div className="px-6 py-4">
//       <div className="font-bold text-xl mb-2 text-[#4D4D4D]">
//         Community Support
//       </div>
//       <p className="text-[#717171] text-base font-light">
//       Find your new crew for tips, help, and a place to fit in.
//       </p>
//     </div>
//   </div>
//   <div className="text-center justify-center items-center shadow-lg px-6.9 py-12 lg:mx-10 lg:w-[24rem] lg:h-[24rem] md:my-10 md:w-[20rem] md:h-[25rem] md:mx-2 transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-3xl">
//     <div className="flex justify-center items-center p-6">
//       <img
//         className="text-center justify-items-center bg-[#FFCFCF] rounded-tr-2xl rounded-tl-2xl rounded-br-2xl p-4 w-20 h-20"
//         src={Essential}
//         alt="Essential Resources"
//       />
//     </div>
//     <div className="px-6 py-4">
//       <div className="font-bold text-xl mb-2 text-[#4D4D4D]">
//         Essential Resources
//       </div>
//       <p className="text-[#717171] text-base font-light">
//       Ditch the jetlag! Get your Canada life figured out with guides on everything from landing to fitting in.

//       </p>
//     </div>
//   </div>
//   <div className="text-center justify-center items-center shadow-lg px-6.9 py-12 lg:mx-10 lg:w-[24rem] lg:h-[24rem] md:my-10 md:w-[20rem] md:h-[25rem] md:mx-2 transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-3xl">
//     <div className="flex justify-center items-center rounded-lg p-6">
//       <img
//         className="justify-center items-center bg-[#CADBFF] rounded-tr-2xl rounded-tl-2xl rounded-br-2xl p-4 w-20 h-20"
//         src={Engagement}
//         alt="Engagement"
//       />
//     </div>
//     <div className="px-6 py-4">
//       <div className="font-bold text-xl mb-2 text-[#4D4D4D]">Engagement</div>
//       <p className="text-[#717171] text-base font-light">
//       Find local events to immerse yourself in Canadian culture and build your community
//       </p>
//     </div>
//   </div>
// </div>


  );
};

export default Explorecard;
