import React from "react";

const Visitor = () => {
  return (
    <div className="p-10 py-[6rem] md:p-8 md:pt-24 lg:px-32 lg:py-24">
      <div>
        <h1 className="text-center font-medium text-3xl px-[0rem] sm:px-0 md:px-6 lg:px-[10rem] p-2 py-6">How to Turn Your Canada Visitor Visa into a Work Permit in 2024</h1>
        <p>
          Want to work in Canada while on a visitor visa? Thanks to a special
          policy valid until February 28, 2025, you can explore job
          opportunities easily. Follow our simple guide to make the most of your
          stay.
        </p>
      </div>
      <div>
        <h3 className="font-medium text-2xl py-4" >Understanding the Unique Policy</h3>
        <p>
          <span className=" font-semibold text-base">Purpose:</span> This policy allows visitors to seek employment
          while in Canada.
        </p>
        <p>
          <span className=" font-semibold text-base">Duration: </span>The policy is active until February 28, 2025.
          Use this time to search for jobs.
        </p>
      </div>
      <div>
        <h3 className="font-medium text-2xl py-4">Steps to Turn Your Visitor Visa into a Work Permit</h3>
        <div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Research Job Market</h3>
            <p>
              Look into the Canadian job market in your field. Identify
              industries and regions where your skills are in high demand.
            </p>
          </div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Update Your Resume</h3>
            <p>
              Make sure your resume is up-to-date and meets Canadian standards.
              Highlight relevant skills, qualifications, and experiences.
            </p>
          </div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Networking</h3>
            <p>
              Attend job fairs, industry events, and networking gatherings.
              Connect with professionals on social media platforms like
              LinkedIn.
            </p>
          </div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Apply for Jobs</h3>
            <p>
              Use online job portals and company websites to apply for jobs.
              Customize each application to match the specific job requirements
              and company culture.
            </p>
          </div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Seek Guidance</h3>
            <p>
              Reach out to local employment agencies and career counselors. Get
              expert advice on navigating the Canadian job market.
            </p>
          </div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Understand Visa Requirements</h3>
            <p>
              Ensure you comply with all visa requirements while job hunting.
              Consult immigration authorities or legal experts if you have
              visa-related questions or concerns.
            </p>
          </div>
        </div>
      </div>
      <div className="py-4">
        <p>
          Take advantage of this unique opportunity to explore job options and
          make your visit to Canada valuable.
        </p>
      </div>
      <div>
        <h1 className="font-medium text-2xl py-4">
          Step-by-Step Guide to Converting a Visitor Visa to a Work Permit in
          Canada
        </h1>
        <div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Step 1: Find Your Ideal Job in Canada</h3>
            <p>
              Look for job offers that match your skills and experience. Use
              online job portals, employment banks, labor market info, and the
              "Working in Canada" tool. Consider using recruitment agencies in
              Canada that help foreign workers connect with employers.
            </p>
          </div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Step 2: Get a Job Offer with LMIA or LMIA-Exempt Status</h3>
            <p>
              Secure a job offer from an employer with a Labour Market Impact
              Assessment (LMIA) or who is exempt from LMIA. LMIA proves no
              Canadian workers are available for the job.
            </p>
            <p>
              Some employers are exempt from LMIA due to special reasons like
              international agreements or provincial nomination processes. Check
              the "Approved LMIA Employers in Canada" list for more info.
            </p>
          </div>
          <div>
            <h3 className=" font-medium text-lg text-gray-500 py-4">Step 3: Apply for a Work Permit</h3>
            <p>
              Attend job fairs, industry events, and networking gatherings.
              Connect with professionals on social media platforms like
              LinkedIn.
            </p>
            <div>
              <p>Medical exam results</p>
              <ul className="list-disc list-inside pl-4">
                <li>Passport</li>
                <li>Visitor visa</li>
                <li>Job offer letter from your employer</li>
                <li>Proof of financial means</li>
                <li>Work permit fee payment</li>
              </ul>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <div>
        <div>
          <h1 className="font-medium text-2xl py-6">Optional Temporary Work Authorization</h1>
          <p>
            If you had a valid work permit in the past 12 months, you can apply
            for temporary work authorization while waiting for your new permit.
            Submit a copy of your previous work permit, the new job offer
            letter, and proof of fee payment.
          </p>
        </div>
        <div>
          <h1 className="font-medium text-2xl py-4">Getting a Visitor Visa (TRV) Outside Canada</h1>
          <p>
            If you're not in Canada but want to work there, you'll need a
            Temporary Residence Visa (TRV). This visa lets you stay in Canada
            for up to six months, during which you can work.
          </p>
        </div>
      </div>
      <div>
        <h1 className="font-medium text-2xl py-4">Visitor Visa Application Steps and Transition to Work in Canada</h1>
      </div>
      <div>
        <div>
          <h3 className=" font-medium text-lg text-gray-500 py-4">Applying for a Visitor Visa</h3>
          <p>
            <span className=" font-semibold text-base">Online Registration:</span> Fill out the online form and
            upload the required documents.
          </p>
          <p>
            <span className=" font-semibold text-base">Payment:</span> Pay the visa fee online using the designated
            portal.
          </p>
          <p>
            <span className=" font-semibold text-base">Biometric Data Collection:</span> Visit a designated service
            location to provide your biometric data.
          </p>
          <p>
            <span className=" font-semibold text-base">Visa Decision Notification:</span> Wait for an email to find
            out if your visitor visa has been approved.
          </p>
        </div>
        <div>
          <h3 className=" font-medium text-lg text-gray-500 py-4">Exploring Work Opportunities in Canada</h3>
          <p>
            <span className=" font-semibold text-base">Start Job Hunting:</span> Once you have your visitor visa,
            look for jobs in Canada that are either LMIA-approved (Labour Market
            Impact Assessment) or exempt from LMIA.
          </p>
          <p>
            <span className=" font-semibold text-base">Apply for a Work Visa:</span> Use the steps mentioned earlier
            to apply for a work visa once you get a job offer.
          </p>
        </div>
        <div>
          <h3 className=" font-medium text-lg text-gray-500 py-4">Transitioning from Visitor to Worker</h3>
          <p>
            Canada's visitor-to-work permit transition allows you to seek job
            opportunities without leaving the country. By following these steps,
            you can smoothly move from being a visitor to a worker in Canada.
          </p>
          <p className="py-4" >
            <span className=" font-semibold text-base">Information from:</span>{" "}
            <a
              href="https://fhc-ng.com/convert-your-canada-visitor-visa-to-a-work-permit/
"
              target="blank"
              className="text-sky-600"
            >
              https://fhc-ng.com/convert-your-canada-visitor-visa-to-a-work-permit/
            </a>
          </p>
        </div>
        <div className="pt-6 text-sm ">
          <p> <span className=" font-semibold ">Disclaimer:</span> The information provided on this Site is for general informational purposes only. Our Goal is to Connect Newcomers to Canada's Vibrant Community and Resources. 
          </p>
        </div>
      </div>
    </div>
  );
};

export default Visitor;
