import React, { useEffect, useState } from "react";
import axios from "axios";


const Blog = () => {
  const [posts, setPosts] = useState([]);

  const getPostData = () => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@homeinc.2024"
      )
      .then((res) => {
        setPosts(res.data.items);
      })
      .catch((error) => {
        console.error("Error fetching blog posts:", error);
      });
  };
  useEffect(() => {
    getPostData();
  }, []);

  const createSummary = (content, length) => {
    const text = content.replace(/<[^>]*>/g, ''); // Strip HTML tags
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  const extractImage = (content) => {
    const imgTag = content.match(/<img.*?src="(.*?)"/);
    return imgTag ? imgTag[1] : null;
  };

  
  return (
    <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-2 sm:gap-2 md:gap-4 lg:gap-4 xl:gap-6 ">
      {posts.map((post) => (
        <div key={post.guid} className="bg-white p-4 mb-4 transition duration-500 ease-in-out hover:shadow-xl hover:scale-110 rounded-xl overflow-hidden shadow-lg">
          {extractImage(post.content) && (
            <img
              src={extractImage(post.content)}
              alt={post.title}
              className="w-full h-[15rem] mb-4"
            />
          )}
          <h2 className="text-xl font-bold">{post.title}</h2>
          <p className="mt-2">
            {createSummary(post.content, 90 )}
            
          </p>
          <a
            href={post.link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 mt-4 block"
          >
            Read more
          </a>
        </div>
      ))}
    </div>
  );
};

const Resources = () => {
  return (
    <div className="bg-[#F5F7FA] px-4 md:px-10 lg:px-32 pt-32 pb-[15rem]">
       <h1  className=" font-semibold text-5xl py-4 text-[#0051F9]"> Blogs</h1>
      <div className="mt-[5rem]">
       
      <Blog />
      </div>
      
      
    </div>
  );
};

export default Resources;
