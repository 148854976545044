import React from "react";
import Immigration from "../assets/images/immigration.svg";
import School from "../assets/images/school.svg";
import Personplay from "../assets/images/personplay.svg";
import Skillet from "../assets/images/skillet.svg";
import Bed from "../assets/images/bed.svg";
import Businesscenter from "../assets/images/businesscenter.svg";

const Empowering = () => {
  return (
    <div>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 gap-8 sm:gap-12 lg:gap-14">
        <div className="m-4 text-center flex flex-col items-center justify-center">
          <img src={Immigration} alt="immigration" className="w-12 h-12 sm:w-20 sm:h-20 lg:w-20 lg:h-20" />
          <p>Immigration</p>
        </div>
        <div className="m-4 text-center flex flex-col items-center justify-center ">
          <img src={School} alt="school" className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24" />
          <p>School</p>
        </div>
        <div className="m-4 text-center flex flex-col items-center justify-center">
          <img src={Personplay} alt="meetup" className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24" />
          <p>Meetup</p>
        </div>
        <div className="m-4 text-center flex flex-col items-center justify-center">
          <img src={Skillet} alt="daily life" className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24" />
          <p>Daily Life</p>
        </div>
        <div className="m-4 text-center flex flex-col items-center justify-center">
          <img src={Bed} alt="housing" className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24" />
          <p>Housing</p>
        </div>
        <div className="m-4 text-center flex flex-col items-center justify-center">
          <img src={Businesscenter} alt="career" className="w-16 h-16 sm:w-20 sm:h-20 lg:w-24 lg:h-24" />
          <p>Career</p>
        </div>
      </div>

      
    </div>
  );
};

export default Empowering;
