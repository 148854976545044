import React from "react";

const Privacy = () => {
  return (
    <div className="p-10 py-[6rem] md:p-8 md:pt-24 lg:px-32 lg:py-24">
      <div>
        <h1 className="font-medium text-3xl px-[0rem] p-2 py-6">
          Privacy Policy
        </h1>
        <p className="text-sky-600 pb-4">Effective Date: 17/08/2023</p>
        <p>
          We are committed to safeguarding your privacy. This Privacy Policy
          explains how we collect, use, share, and protect your personal
          information when you use our mobile application and services. By
          accessing or using the App, you consent to the practices described in
          this Privacy Policy.
        </p>
      </div>
      <div className="py-4">
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6 list">
            1. Information We Collect:
          </p>
          <div>
            <ul className="list-disc pl-8 pt-4">
              <li>
                Personal Information: We may collect personal information, such
                as your name, email address, profile picture, and contact
                details when you register for an account.
              </li>
              <li>
                User-Generated Content: Information you voluntarily provide,
                such as posts, photos, comments, and other content shared within
                the App.
              </li>
              <li>
                Device Information: We collect device-specific information,
                including device type, operating system, unique device
                identifiers, IP address, and mobile network information.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            2. How We Use Your Information:
          </p>

          <div>
            <ul className="list-disc pl-8 pt-4">
              <li>
                Provide, Improve, and Personalize Services: We use your
                information to provide, analyze, improve, and personalize the
                App's features, functionality, and user experience.
              </li>
              <li>
                Communication: We may use your email address to send you
                notifications, updates, and promotional materials.
              </li>
              <li>
                User Interaction: Your information enables you to interact with
                other users, share content, and engage in discussions within the
                App.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            3. Sharing of Your Information:
          </p>
          <div>
            <ul className="list-disc pl-8 pt-4">
              <li>
                Public Content: Content you post within the App may be viewed by
                other users.
              </li>
              <li>
                Third-Party Services: We may share information with third-party
                services, such as hosting providers and analytics partners, to
                support and optimize the App.
              </li>
              <li>
                Legal and Safety: We may disclose your information to comply
                with legal obligations, protect our rights and safety, and
                prevent fraud.
              </li>
            </ul>
          </div>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            4. Your Choices
          </p>
          <div>
            <ul className="list-disc pl-8 pt-4">
                <li>
                Account Settings: You can update and manage your account information and privacy settings within the App.
                </li>
                <li>
                Opt-Out: You can opt-out of receiving promotional emails by following the instructions provided.
                </li>
                <li>Data Retention: You can request the removal of your account and data by contacting us at <a href="mailto:admin@home4im.com " className="text-sky-600">admin@home4im.com</a> </li>
            </ul>
            </div>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            5. Security:
          </p>
          <p>
          We implement reasonable security measures to protect your information from unauthorized access, alteration, or destruction.
          </p>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            6. Children's Privacy:

          </p>
          <p>
          Children's Privacy: The App is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you believe we have collected information from a child under 13, please contact us immediately.
          </p>
          
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            7. Changes to Privacy Policy:
          </p>
          <p>
          We may update this Privacy Policy to reflect changes in our practices or legal requirements. We will notify you of any significant changes via email or within the App.
          </p>
        </div>
        <div>
          <p className="font-medium text-lg px-[0rem] p-2 py-6">
            8. Contact Us:
          </p>
          <p>
          If you have any questions, concerns, or requests regarding your privacy or this Privacy Policy, please contact us at
            <a href="mailto:admin@home4im.com" className="text-sky-600">
              {" "}
              admin@home4im.com
            </a>. Thank you for using Home 4Im. Your privacy matters to us.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
