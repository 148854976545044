import React from "react";
import MobilePhone from "../assets/images/communitymobile.svg";
import { ReactTyped } from "react-typed";
import ExperienceMobile from "../assets/images/experiencemobile.svg";
import MobileSection from "../assets/images/mobilesection.svg";
import HandPhone from "../assets/images/handphone.svg";
import Explorecard from "./Explorecard";
import Empowering from "./Empowering";
import Googleplay from "../assets/images/googleplay.svg";
import Applepay from "../assets/images/applestore.svg";
import PartnersImage from "../assets/images/partners.jpeg";
import Robbin from "../assets/images/rabboniStudio3.svg";
import Panafrican from "../assets/images/4.png";
import Easygo from "../assets/images/5.png";
import Festival from "../assets/images/6.png";


import './styles.css';


const Home = () => {
  // const videoUrl = 'https://res.cloudinary.com/dammysaina/video/upload/v1721748522/Motion_1.mp4';

  const VideoPlayer = ({ videoUrl }) => {
    return (
      <div className="video-container">
        <video width="2000" className="video-custom-height" autoPlay muted controls>
          <source src="https://res.cloudinary.com/dammysaina/video/upload/v1721748522/Motion_1.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  };

  return (
    <div>
      {/* Welcomehome */}
      <div
        name="home"
        className="  w-full h-auto  bg-[#F5F7FA]  sm:py-10 lg:py-20  md:px-10 lg:px-32 md:h-auto"
      >
        {/*Container*/}
        
          <div className="text-center justify-center sm:mt-[5rem] md:pr-0 py-32 sm:ty-24 md:py-16 lg:py-8">
            <h1 className="text-4xl sm:text-5xl md:text-4xl lg:text-6xl font-bold text-black  ">
              <ReactTyped
                strings={[
                  "Welcome to <span style='color:#0051F9'>Home</span> ",
                ]}
                typeSpeed={100}
                loop
                backSpeed={20}
              />
            </h1>
            <p className="text-[#8892b0] px-5 md:px-0 lg:px-0 py-2 pr-0  text-center  ">
              Your Hub for Newcomers in Canada. Connect, learn, and thrive in
              your new community!
            </p>
          </div>
       
        <div className="" >
          <VideoPlayer />
         
        </div>
      </div>

      {/* advantages */}
      <div className="  mt-[5rem] md:my-14 lg:my-14">
        <div className="text-center justify-center items-center px-4 md:px-10">
          <h1 className="text-lg sm:text-6xl font-bold text-[#4D4D4D] px-0 md:px-10 lg:px-10 md:text-5xl max-w-[800px] mx-auto">
            Explore the Advantages of Joining Home App
          </h1>
          <p className="text-[#8892b0] py-10 sm:py-6 md:py-10 px-0  sm:px-10 md:px-20 lg:px-36  max-w-[800px] mx-auto">
            Connecting Newcomers to Canada's Vibrant Community and Resources
          </p>
        </div>
        <div className=" px-8 md:px-0">
          <Explorecard />
        </div>
      </div>

      {/* Partners */}
      <div className="my-4 md:my-14 lg:my-14">
        <div className="text-center justify-center items-center px-4 md:px-10">
          <h1 className="text-lg sm:text-6xl font-bold text-[#4D4D4D] px-0 md:px-10 lg:px-10 md:text-5xl max-w-[800px] mx-auto">
            Partners
          </h1>
          <div className="flex flex-col md:flex-row justify-center items-center w-full gap-8 sm:gap-0 lg:gap-14" >
            <img
              
              src={PartnersImage}
              alt="partnersimage" className="w-[25rem] h-[15rem]"
            />
            <div className="grid justify-center items-center grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 ">
            <img src={Robbin} alt="partnerimage" className="w-[10rem] h-[10rem] "/>
            <img src={Panafrican} alt="partnerimage" className="w-[10rem] h-[10rem] "/>
            <img src={Easygo} alt="partnerimage" className="w-[10rem] h-[10rem] " />
            <img src={Festival} alt="partnerimage" className="w-[10rem] h-[10rem]" />
            </div>
            
          </div>
        </div>
      </div>

      {/* joincommunity */}
      <div className=" flex flex-col md:flex-row justify-between w-full px-4 md:px-10 lg:px-32 pt-6">
        <div className="flex justify-center md:justify-start mb-8 md:mb-0 md:pr-10">
          <img
            src={MobilePhone}
            alt="phone"
            className="w-full  h-auto md:w-180 md:h-140"
          />
        </div>

        <div className="flex flex-col items-center md:items-start text-center md:mt-10 lg:mt-44 md:ml-10">
          <h1 className="text-3xl sm:text-5xl md:text-5xl lg:text-4xl font-semibold text-[#4D4D4D] max-w-[300px] md:text-left">
            Join the <span className=" text-[#F23838]">Community</span>
          </h1>
          <p className="text-[#8892b0] py-4 max-w-[690px] text-center md:text-left pr-0  md:pr-5 lg:pr-40 ">
            Connect with Your New Community. At Home, we understand that the
            journey of settling into a new country can be both exciting and
            challenging. Whether you have questions about navigating immigration
            processes, finding accommodation, or simply want to share your
            journey, our forum provides a supportive space for open dialogue.
          </p>
        </div>
      </div>

      {/* empoweryou */}
      <div className=" flex flex-col md:flex-row px-6 md:px-16 lg:px-32 py-10 md:py-20 bg-[#F5F7FA]">
        <div className="md:mt-20">
          <h1 className="text-3xl sm:text-4xl font-semibold text-[#4D4D4D] max-w-[700px] md:text-4xl ">
            Empowering{" "}
            <span className="text-4xl sm:text-4xl font-semibold text-[#F7BD2D] md:text-4xl">
              You
            </span>
          </h1>
          <p className="text-[#8892b0] py-4 md:pr-40 max-w-[690px] ">
            Essential Resources for Newcomers. Access guides, tools, and
            information to navigate life in Canada confidently.
          </p>
        </div>
        <div className="mt-10 md:mt-0 md:ml-20 lg:ml-56 ">
          <Empowering />
        </div>
      </div>

      {/* shareexperience */}
      <div className=" flex flex-col md:flex-row justify-between w-full px-4 sm:px-4 md:px-10 lg:px-32">
        <div className="flex justify-center md:justify-start mb-8 md:mb-0 md:pr-10">
          <img
            src={ExperienceMobile}
            alt="phone"
            className="w-full h-auto md:w-180 md:h-140"
          />
        </div>

        <div className="flex flex-col items-center md:items-start md:mt-10 lg:mt-44 md:ml-10 text-center md:text-left">
          <h1 className="text-4xl sm:text-5xl md:text-5xl lg:text-4xl font-semibold text-[#4D4D4D] max-w-[350px] md:text-left">
            Share your{" "}
            <span className="text-4xl sm:text-5xl md:text-5xl lg:text-4xl  font-semibold text-[#B31D89]">
              Experience
            </span>
          </h1>
          <p className="text-[#8892b0] py-4 max-w-[690px] text-center md:text-left sm:pr-2 md:pr-5 lg:pr-40  ">
            At Home, we champion collective action and community strength. Your
            involvement can make a profound impact on newcomers' lives across
            Canada. Share your journey, offer guidance, and provide support to
            those starting anew in Canada. Together, let's make a difference.
          </p>
        </div>
      </div>

      {/* mobilesection */}
      <div>
        <img src={MobileSection} alt="phone" className="w-full " />
      </div>
      <div className=" flex flex-col md:flex-row justify-between w-full bg-[#F5F7FA] px-4 md:px-10 lg:px-32 pt-8 md:pt-16 ">
        {/*Container*/}
        <div className="flex flex-col items-center md:items-start md:mt-10 lg:mt-20 stext-center md:text-left">
          <h1 className="text-4xl sm:text-5xl md:text-4xl lg:text-5xl font-bold text-black md:max-w-[350px] lg:max-w-[500px]">
            Experience <span className="text-[#0051F9]">Home </span>
            on the Go
          </h1>

          <p className="text-[#8892b0] py-4 max-w-[600px]  sm:text-center md:text-left lg:text-left sm:pr-2 md:pr-5 lg:pr-40 ">
            Stay Connected, Anytime, Anywhere. Download the Home App today to
            unlock a world of opportunities for newcomers in Canada.
          </p>
          <div className="flex flex-row items-center my-2">
            <div>
              <a href="https://t.co/tFBcSChHXn" target="blank">
                <img
                  src={Googleplay}
                  alt="googleplay"
                  className=" w-28 h-28 md:w-32 lg:w-32 lg:h-32 sm:w-20"
                />
              </a>
            </div>
            <div className="ml-4">
              <a href="https://apps.apple.com/app/home4im/id6471267114" target="blank">
              <img
                src={Applepay}
                alt="googleplay"
                className=" w-28 h-28 ml-4 sm:w-20 md:w-32 md:h-32 lg:w-32 lg:h-32"
              />
              </a>
            </div>
          </div>
        </div>
        <div className="flex justify-center  md:mt-0">
          <img
            src={HandPhone}
            alt="homeimage"
            className=" md:w-180 lg:w-180 md:h-140 lg:h-140 md:mt-10"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
